import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useBlogPostService from '../services/blogPostService';
import MDEditor from '@uiw/react-md-editor';
import Switch from 'react-bootstrap/Switch';

const BlogPostEdit = () => {
    const navigate = useNavigate();
    const { postId } = useParams();

    const { getBlogPostById, updateBlogPost } = useBlogPostService();
    const { user } = useAuth0();
    const [error, setError] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isHome, setIsHome] = useState(true);
    const [showPreview, setShowPreview] = useState(false);

    const [blogPost, setBlogPost] = useState([]);

    useEffect(() => {
        const fetchAndSetBlogViews = async () => {
            try {
                const post = await getBlogPostById(postId);
                initIsHome(post.categoryNames);
                setBlogPost(post);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchAndSetBlogViews();
    }, []);

    const updateMd = (text) => {
        setBlogPost({ ...blogPost, body: text });
    }

    const userHasAdminRole = () => {
        return user?.['https://gluck.blog/roles']?.includes('BlogAdmin');
    };

    const handleSetShowPreview = (showPrev) => {
        setShowPreview(showPrev);
    }

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setBlogPost({ ...blogPost, [name]: value });
    };

    const handleSetIsHome = (isHome) => {
        setIsHome(isHome);
        updateCategoryNames(isHome);
    }

    const initIsHome = (categories) => {
        var isHomeValue = categories.includes("home");
        handleSetIsHome(isHomeValue);
    }

    const updateCategoryNames = (isHome) => {
        const newCategoryName = isHome
            ? 'home'
            : 'road';
        console.log(newCategoryName)
        console.log(blogPost)
        setBlogPost({ ...blogPost, categoryNames: [newCategoryName] });
    };

    const handleSave = async (isSubmit) => {
        setIsLoading(true);
        try {
            const response = await updateBlogPost(postId, blogPost);

            if (response.ok) {
                console.log('Blog post created successfully!');
                if (isSubmit) {
                    setBlogPost({ Header: "", ImageUrl: "", Body: "" });
                    navigate('/posts');
                }
            } else {
                console.error('Blog post creation failed!');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className='mainContainerSinglePost'>
            <Row>
                <Col xs={9} xl={9} className='postContainerSinglePost'>
                    <div className='user-form'>
                        <div className='heading container'>
                            {isLoading && <p>Loading...</p>}
                            {Object.keys(error).length > 0 && <p>Error: {error}</p>}
                            <p>Edit Blog Post</p>

                            <div style={{ margin: '5px' }}>
                                <Switch
                                    checked={showPreview}
                                    onChange={() => handleSetShowPreview(!showPreview)}
                                    label={'Show preview'}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
                                <span style={{ marginRight: '8px' }}>Road</span>
                                <Switch
                                    checked={isHome}
                                    onChange={() => handleSetIsHome(!isHome)}
                                />
                                <span>Home</span>
                            </div>

                        </div>
                        {blogPost.Header != null > 0 &&
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission
                                    handleSave(true); // Call save logic with a flag indicating submission
                                }}
                            >
                                <div className="mb-3 container">
                                    <div className="mb-3">
                                        <label htmlFor="header" className="form-label">
                                            Header
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="header"
                                            name="header"
                                            value={blogPost.header}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 mt-3 container">
                                    <label htmlFor="imageUrl" className="form-label">Image URL</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="imageUrl"
                                        name="imageUrl"
                                        value={blogPost.imageUrl}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-12 container" data-color-mode="light">
                                    <label htmlFor="body" className="form-label">Body</label>
                                    <MDEditor
                                        value={blogPost.body}
                                        onChange={updateMd}
                                    />
                                </div>
                                <br />
                                {
                                    showPreview &&
                                    <div data-color-mode="light">
                                        <MDEditor.Markdown source={blogPost.body} />
                                    </div>
                                }
                                <div>
                                    <label htmlFor="jscode">JavaScript Code</label>
                                    <textarea
                                        className="form-control"
                                        id="jsCode"
                                        name="jsCode"
                                        value={blogPost.jsCode}
                                        onChange={handleInputChange}
                                        rows="6"
                                    />
                                </div>

                                {userHasAdminRole() &&
                                    <div className="button-group">
                                        <button
                                            type="button"
                                            className="btn btn-secondary save-btn me-2"
                                            onClick={() => handleSave(false)} // Save without submitting
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary submit-btn"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                }
                            </form>
                        }

                    </div>
                </Col>
                <Col xs={3} xl={3}>
                    <div className="vertical-line"></div>
                </Col>
            </Row>
        </div>
    );
};


export default withAuthenticationRequired(BlogPostEdit, {
    onRedirecting: () => (
        <div>Redirecting you to the login page...</div>
    )
});